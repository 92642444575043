import {
  GraphQLLayoutService,
  RestLayoutService,
  constants,
} from '@sitecore-jss/sitecore-jss-react';

export class LayoutServiceFactory {
  // Add configuration if required (when not using ssr or using connected graphql)
  // Use clientFactory if using connected graphql
  create() {
    return process.env.REACT_APP_FETCH_WITH === constants.FETCH_WITH.GRAPHQL
      ? new GraphQLLayoutService({})
      : new RestLayoutService({});
  }
}

export const layoutServiceFactory = new LayoutServiceFactory();
